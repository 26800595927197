// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { COLORS, TYPOGRAPHY } from '@app/constants';

import { ButtonHTMLAttributes, cloneElement, FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../Spinner';

type StyleButton = 'plain' | 'primary' | 'cancel';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: StyleButton;
  isLoading?: boolean;
  isDisabled?: boolean;
  icon?: ReactElement;
  isReversed?: boolean;
  className?: string;
}

const FlatBtn: FC<Props> = ({
  styleType = 'plain',
  isLoading = false,
  isDisabled = false,
  icon,
  isReversed = false,
  className,
  onClick,
  ...props
}) => {
  const cloneIcon =
    icon &&
    cloneElement(icon, {
      color: icon.props.color || 'currentColor',
    });

  return (
    <Root
      $type={styleType}
      $isLoading={isLoading}
      disabled={isDisabled}
      $isReversed={isReversed}
      className={className}
      onClick={(event) => {
        if (onClick && !isLoading) onClick(event);
      }}
      {...props}
    >
      {icon && !isLoading && <Icon $isReversed={isReversed}>{cloneIcon}</Icon>}
      <span>{props.children}</span>
      {isLoading && (
        <StyledSpinner
          isLoading={isLoading}
          color={styleType === 'cancel' ? COLORS.systemError : undefined}
        />
      )}
    </Root>
  );
};

export default FlatBtn;

interface StyleProps {
  $type: StyleButton;
  $isLoading: boolean;
  $isReversed: boolean;
}

const Icon = styled.div<{ $isReversed: boolean }>`
  ${({ $isReversed }) =>
    $isReversed
      ? css`
          margin-left: 15px;
        `
      : css`
          margin-right: 15px;
        `};
`;

const Root = styled.button<StyleProps>`
  ${TYPOGRAPHY.body1Regular14}

  position: relative;
  display: flex;
  flex-direction: ${({ $isReversed }) => $isReversed && 'row-reverse'};
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  padding: 2px 12px;

  background-color: transparent;

  ${({ $type }) => {
    switch ($type) {
      case 'primary':
        return css`
          color: ${COLORS.primary300};

          &:not([disabled]) {
            &:hover {
              color: ${COLORS.elementsStatesHover};
            }
            &:active {
              color: ${COLORS.primary400};
            }
            &:not(:hover:active):focus {
              color: ${COLORS.primary300};
            }
          }
        `;
      case 'cancel':
        return css`
          color: ${COLORS.systemError};

          &:not([disabled]) {
            &:active {
              color: ${COLORS.systemErrorLight};
            }
          }
        `;
      default:
        return css`
          color: ${COLORS.grayscale700};

          &:not([disabled]) {
            &:hover {
              color: ${COLORS.elementsStatesHover};
            }
            &:active {
              color: ${COLORS.primary400};
            }
            &:not(:hover:active):focus {
              color: ${COLORS.primary300};
            }
          }
        `;
    }
  }};

  &:not([disabled]) {
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px ${COLORS.elementsStatesFocusedBorder};
    }
  }

  &[disabled] {
    opacity: 0.55;
    color: ${COLORS.grayscale700};
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      cursor: default;

      & > span {
        opacity: 0;
      }
    `};
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 0;
  left: 0;
`;
