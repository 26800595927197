// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { ButtonHTMLAttributes, cloneElement, FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { COLORS } from '@app/constants';
import Spinner from '../Spinner';

type StyleButton = 'secondary' | 'flat' | 'cancel';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: StyleButton;
  isLoading?: boolean;
  isDisabled?: boolean;
  isLarge?: boolean;
  children: ReactElement;
  className?: string;
}

const IconBtn: FC<Props> = ({
  styleType = 'secondary',
  isLoading = false,
  isDisabled = false,
  isLarge = false,
  children: icon,
  className,
  onClick,
  ...props
}) => {
  const spinnerColor = styleType === 'cancel' ? COLORS.systemError : undefined;

  const cloneIcon =
    icon &&
    cloneElement(icon, {
      color: icon.props.color || 'currentColor',
    });

  return (
    <Root
      $type={styleType}
      $isLoading={isLoading}
      disabled={isDisabled}
      $isLarge={isLarge}
      className={className}
      onClick={(event) => {
        if (onClick && !isLoading) onClick(event);
      }}
      {...props}
    >
      <span>{cloneIcon}</span>
      {isLoading && (
        <StyledSpinner isLoading={isLoading} color={spinnerColor} />
      )}
    </Root>
  );
};

export default IconBtn;

interface StyleProps {
  $type: StyleButton;
  $isLoading: boolean;
  $isLarge: boolean;
}

const Root = styled.button<StyleProps>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: ${({ $isLarge }) => ($isLarge ? '14px' : '10px')};

  & > span {
    display: flex;
    color: ${COLORS.primary300};
  }

  &:not([disabled]) {
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px ${COLORS.elementsStatesFocusedBorder};
    }
  }

  ${({ $type }) => {
    switch ($type) {
      case 'secondary':
        return css`
          & > span {
            color: ${COLORS.primary300};
          }
          background-color: ${COLORS.primary50};

          &:not([disabled]) {
            &:hover {
              & > span {
                color: ${COLORS.primary400};
              }
            }
            &:active {
              & > span {
                color: ${COLORS.primary500};
              }
            }
          }
          &[disabled] {
            opacity: 0.45;
          }
        `;
      case 'cancel':
        return css`
          & > span {
            color: ${COLORS.systemError};
          }
          background-color: ${COLORS.systemErrorExtraLightRed};

          &:not([disabled]) {
            &:not(:active):hover {
              & > span {
                color: ${COLORS.systemErrorLight};
              }
            }
          }

          &[disabled] {
            opacity: 0.45;
          }
        `;
      default:
        return css`
          & > span {
            color: ${COLORS.grayscale600};
          }
          background-color: transparent;

          &:not([disabled]) {
            &:hover {
              & > span {
                color: ${COLORS.primary300};
              }
            }
            &:active {
              & > span {
                color: ${COLORS.primary400};
              }
            }
          }
          &[disabled] {
            opacity: 0.45;
          }
        `;
    }
  }};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      cursor: default;

      & > span {
        opacity: 0;
      }
    `};
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 0;
  left: 0;
`;
