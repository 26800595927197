import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const LogoMedsiXPogovorim: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  width,
  height,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 633 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14049_27543)">
        <mask
          id="mask0_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="30"
          width="26"
          height="33"
        >
          <path
            d="M0.778687 30.4307H25.1261V62.4307H0.778687V30.4307Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2455 30.3662C15.9479 30.3662 19.6978 31.2459 22.3778 32.7439V38.9055C19.742 37.0577 16.2162 36.1304 12.7346 36.1779C9.34475 36.2187 7.64641 37.146 7.64641 39.0379C7.64641 44.3639 24.7419 42.3836 24.7419 53.3413C24.7419 60.2536 19.1204 62.4104 12.558 62.4104C8.22724 62.4104 4.20897 61.3982 0.995719 59.6388V53.3413C4.56902 55.6748 8.49558 56.5987 12.0655 56.5987C15.6829 56.5987 17.9146 55.8515 17.9146 53.6504C17.9146 47.8829 0.819092 50.0431 0.819092 39.1262C0.819092 33.1821 6.08394 30.3662 12.2455 30.3662Z"
            fill="#E30016"
          />
        </g>
        <mask
          id="mask1_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="27"
          y="30"
          width="36"
          height="33"
        >
          <path
            d="M27.7345 30.4305H62.5165V62.4306H27.7345V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.8778 30.9803V61.7916H55.1829V42.8212L46.9256 55.1918H43.0432L34.7859 42.777V61.7916H28.0911V30.9803H34.2934L45.0065 47.0025L55.6755 30.9803H61.8778Z"
            fill="#E30016"
          />
        </g>
        <mask
          id="mask2_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="63"
          y="30"
          width="35"
          height="33"
        >
          <path
            d="M63.3866 30.4305H97.299V62.4306H63.3866V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.2289 61.7916L76.9936 30.9803H84.2251L96.9457 61.7916H90.03L87.7509 56.0274H73.4678L71.1479 61.7916H64.2289ZM75.567 50.569H85.6551L81.2802 39.5196C81.056 38.9048 80.8352 38.29 80.611 37.1012C80.3869 38.29 80.1627 38.9048 79.8977 39.5196L75.567 50.569Z"
            fill="#E30016"
          />
        </g>
        <mask
          id="mask3_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="99"
          y="30"
          width="29"
          height="33"
        >
          <path
            d="M99.0375 30.4305H127.733V62.4306H99.0375V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.2958 61.7916V30.9803H112.149C118.222 30.9803 124.56 33.2255 124.56 41.0175C124.56 46.2994 121.524 48.9827 117.73 50.1716L127.057 61.7916H119.336L110.899 51.0105H105.991V61.7916H99.2958ZM105.991 45.5079H112.461C115.274 45.5079 117.73 44.2749 117.73 41.15C117.73 37.9809 115.274 36.7921 112.461 36.7921H105.991V45.5079Z"
            fill="#E30016"
          />
        </g>
        <mask
          id="mask4_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="125"
          y="30"
          width="28"
          height="33"
        >
          <path
            d="M125.994 30.4305H152.081V62.4306H125.994V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M151.353 30.9803V36.9245H142.128V61.7916H135.477V36.9245H126.249V30.9803H151.353Z"
            fill="#E30016"
          />
        </g>
        <mask
          id="mask5_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="289"
          y="30"
          width="7"
          height="33"
        >
          <path
            d="M289.469 30.4305H295.556V62.4306H289.469V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M291.717 46.3877C291.717 51.7238 290.983 56.8902 289.614 61.7916H293.446C294.73 56.8732 295.416 51.7103 295.416 46.3877C295.416 41.0651 294.73 35.9021 293.446 30.9803H289.614C290.983 35.8817 291.717 41.0481 291.717 46.3877Z"
            fill="#00ACA6"
          />
        </g>
        <mask
          id="mask6_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="301"
          y="30"
          width="5"
          height="33"
        >
          <path
            d="M301.643 30.4305H305.383V62.4306H301.643V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M303.58 46.3877C303.58 51.6831 302.982 56.8392 301.854 61.7916H303.746C304.843 56.8324 305.431 51.6797 305.431 46.3877C305.431 41.0956 304.843 35.9429 303.746 30.9803H301.854C302.982 35.9361 303.58 41.0922 303.58 46.3877Z"
            fill="#00ACA6"
          />
        </g>
        <mask
          id="mask7_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="263"
          y="30"
          width="13"
          height="33"
        >
          <path
            d="M263.382 30.4305H275.556V62.4306H263.382V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M267.992 46.3877C267.992 51.9446 266.63 57.1823 264.229 61.7916H272.377C274.317 57.0396 275.387 51.8393 275.387 46.3877C275.387 40.936 274.317 35.7357 272.377 30.9803H264.229C266.63 35.593 267.992 40.8307 267.992 46.3877Z"
            fill="#00ACA6"
          />
        </g>
        <mask
          id="mask8_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="249"
          y="30"
          width="17"
          height="33"
        >
          <path
            d="M249.47 30.4305H265.991V62.4306H249.47V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask8_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M261.243 30.9803H249.653C253.648 34.8967 256.131 40.3518 256.131 46.3877C256.131 52.4236 253.648 57.8786 249.653 61.7916H261.243C263.865 57.2604 265.373 52.0024 265.373 46.3877C265.373 40.773 263.865 35.5149 261.243 30.9803Z"
            fill="#00ACA6"
          />
        </g>
        <mask
          id="mask9_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="276"
          y="30"
          width="10"
          height="33"
        >
          <path
            d="M276.426 30.4305H285.991V62.4306H276.426V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask9_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M279.857 46.3877C279.857 51.7952 278.905 56.9819 277.166 61.7916H283.022C284.568 56.9343 285.4 51.7578 285.4 46.3877C285.4 41.0175 284.568 35.841 283.022 30.9803H277.166C278.905 35.79 279.857 40.9768 279.857 46.3877Z"
            fill="#00ACA6"
          />
        </g>
        <mask
          id="mask10_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="154"
          y="30"
          width="36"
          height="33"
        >
          <path
            d="M154.689 30.4305H189.471V62.4306H154.689V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask10_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188.859 30.9803V61.7916H182.164V42.8212L173.906 55.1918H170.024L161.767 42.777V61.7916H155.072V30.9803H161.274L171.987 47.0025L182.656 30.9803H188.859Z"
            fill="#00ACA6"
          />
        </g>
        <mask
          id="mask11_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="194"
          y="30"
          width="24"
          height="33"
        >
          <path
            d="M194.688 30.4305H217.297V62.4306H194.688V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask11_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M217.029 30.9803V36.7921H201.72V43.351H215.69V49.1152H201.72V55.9833H217.297V61.7916H195.025V30.9803H217.029Z"
            fill="#00ACA6"
          />
        </g>
        <mask
          id="mask12_14049_27543"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="221"
          y="30"
          width="31"
          height="33"
        >
          <path
            d="M221.644 30.4305H251.209V62.4306H221.644V30.4305Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask12_14049_27543)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M234.206 30.9803C245.32 30.9803 250.584 37.451 250.584 46.3877C250.584 55.3209 245.32 61.7916 234.206 61.7916H222.511V30.9803H234.206ZM234.206 36.7921H229.206V55.9833H234.206C240.408 55.9833 243.757 52.7259 243.757 46.3877C243.757 40.0495 240.408 36.7921 234.206 36.7921Z"
            fill="#00ACA6"
          />
        </g>
      </g>
      <line
        x1="327.718"
        y1="33.0098"
        x2="356.053"
        y2="61.345"
        stroke="black"
        strokeWidth="3.06964"
      />
      <line
        x1="356.695"
        y1="33.4614"
        x2="328.36"
        y2="61.7965"
        stroke="black"
        strokeWidth="3.06964"
      />
      <g clipPath="url(#clip1_14049_27543)">
        <path
          d="M405.066 29.1492H382.182H376.438V62.8944H382.182V34.3503H405.066V62.8944H410.81V29.1492H405.066Z"
          fill="#001F1B"
        />
        <path
          d="M411.323 50.4361C411.323 41.6377 416.339 36.1251 425.954 36.1251C435.6 36.1251 440.57 41.6377 440.57 50.4361C440.57 59.1877 435.6 64.638 425.954 64.638C416.339 64.638 411.323 59.1722 411.323 50.4361ZM435.399 50.4361C435.399 44.0047 431.838 40.8747 425.939 40.8747C420.086 40.8747 416.525 44.0203 416.525 50.4361C416.525 56.774 420.086 59.9041 425.939 59.9041C431.822 59.9041 435.399 56.7585 435.399 50.4361Z"
          fill="#001F1B"
        />
        <path
          d="M456.331 50.4361C456.331 41.6377 461.347 36.1251 470.947 36.1251C480.593 36.1251 485.563 41.6377 485.563 50.4361C485.563 59.1877 480.593 64.638 470.947 64.638C461.347 64.638 456.331 59.1722 456.331 50.4361ZM480.407 50.4361C480.407 44.0047 476.846 40.8747 470.947 40.8747C465.094 40.8747 461.533 44.0203 461.533 50.4361C461.533 56.774 465.11 59.9041 470.947 59.9041C476.831 59.9041 480.407 56.7585 480.407 50.4361Z"
          fill="#001F1B"
        />
        <path
          d="M510.722 50.4361C510.722 41.6377 515.739 36.1251 525.354 36.1251C535 36.1251 539.97 41.6377 539.97 50.4361C539.97 59.1877 535 64.638 525.354 64.638C515.754 64.638 510.722 59.1722 510.722 50.4361ZM534.799 50.4361C534.799 44.0047 531.238 40.8747 525.338 40.8747C519.486 40.8747 515.925 44.0203 515.925 50.4361C515.925 56.774 519.486 59.9041 525.338 59.9041C531.238 59.9041 534.799 56.7585 534.799 50.4361Z"
          fill="#001F1B"
        />
        <path
          d="M510.585 56.1823C510.585 60.8072 506.544 63.2365 500.521 63.2365H486.323V37.5266H499.468C505.088 37.5266 509.13 39.8469 509.13 44.067C509.13 47.0102 507.07 49.6575 503.308 49.9066C507.272 50.1713 510.585 52.0089 510.585 56.1823ZM491.463 48.0846H498.198C501.636 48.0846 503.927 47.5863 503.927 44.7677C503.927 41.9492 501.636 41.7623 498.198 41.7623H491.463V48.0846ZM505.259 55.7462C505.259 52.8498 502.952 52.2113 499.127 52.2113H491.479V59.2811H499.127C502.952 59.2811 505.259 58.6427 505.259 55.7462Z"
          fill="#001F1B"
        />
        <path
          d="M441.22 63.2365V37.5266H451.593H458.251V41.7623H446.376V53.3948V63.2365H441.22Z"
          fill="#001F1B"
        />
        <path
          d="M546.04 58.3778V72.2995H540.606V37.3396H543.455L546.04 41.9179C548.224 38.6944 551.212 36.4053 556.646 36.4053C564.914 36.4053 570.396 41.8244 570.396 50.1401C570.396 58.409 564.914 63.8748 556.646 63.8748C551.212 63.8904 548.224 61.6013 546.04 58.3778ZM546.04 49.0811V51.2457C546.629 57.132 551.026 59.2187 555.253 59.2187C560.827 59.2187 565.1 55.9953 565.1 50.1556C565.1 44.316 560.424 41.0458 555.253 41.0458C551.026 41.0303 546.706 43.771 546.04 49.0811Z"
          fill="#001F1B"
        />
        <path
          d="M599.706 37.6511V63.252H596.315L594.271 58.3C592.042 61.9439 587.861 64.1864 582.659 64.1864C574.902 64.1864 571.047 59.1876 571.047 52.2735V37.6511H576.435V51.8998C576.435 56.9452 578.989 59.6548 584.052 59.6548C588.419 59.6548 592.088 57.5993 594.271 53.3947V37.6511H599.706Z"
          fill="#001F1B"
        />
        <path
          d="M628.782 37.62V63.2832H623.858V44.9857L611.72 62.5669H611.456L599.318 44.7054V63.2832H596.592L594.549 58.3156V37.62H599.828L611.673 55.0921L623.518 37.62H628.782Z"
          fill="#001F1B"
        />
        <path
          d="M617.216 72.2995C617.216 75.2427 614.847 77.6252 611.921 77.6252C608.995 77.6252 606.626 75.2427 606.626 72.2995C606.626 69.3563 608.995 66.9738 611.921 66.9738C614.847 66.9738 617.216 69.3563 617.216 72.2995Z"
          fill="#B8362D"
        />
        <path
          d="M632.096 72.2995C632.096 75.2427 629.727 77.6252 626.801 77.6252C623.875 77.6252 621.506 75.2427 621.506 72.2995C621.506 69.3563 623.875 66.9738 626.801 66.9738C629.727 66.9738 632.096 69.3563 632.096 72.2995Z"
          fill="#001F1B"
        />
        <path
          d="M602.321 72.2995C602.321 75.2427 599.952 77.6252 597.026 77.6252C594.099 77.6252 591.73 75.2427 591.73 72.2995C591.73 69.3563 594.099 66.9738 597.026 66.9738C599.952 66.9738 602.321 69.3563 602.321 72.2995Z"
          fill="#64AB8A"
        />
        <path
          d="M612.045 13.7322H614.554V21.6741H626.274V13.7322H628.783V33.0263H626.274V23.8542H614.554V33.0263H612.045V13.7322Z"
          fill="#001F1B"
        />
        <path
          d="M540.497 13.7322H543.005V21.6741H554.726V13.7322H557.234V33.0263H554.726V23.8542H543.005V33.0263H540.497V13.7322Z"
          fill="#001F1B"
        />
        <path
          d="M597.119 32.8391C595.974 33.1505 595.478 33.2284 594.797 33.2284C593.156 33.2284 592.289 32.1228 592.289 29.9582C589.78 32.6834 587.659 33.5866 583.897 33.5866C579.267 33.5866 576.403 31.422 576.403 27.9494C576.403 25.6602 577.425 24.0874 579.546 23.1375C580.521 22.7015 581.868 22.3901 584.33 22.0786L588.34 21.5336C591.36 21.0976 592.258 20.5058 592.258 18.8863C592.258 16.6439 590.26 15.4448 586.56 15.4448C582.085 15.4448 580.041 16.784 579.732 19.9919H577.177C577.487 17.9052 577.889 16.8775 578.818 15.8497C580.428 13.9499 582.735 13.2024 586.668 13.2024C590.167 13.2024 592.397 13.9966 593.651 15.7251C594.518 16.8775 594.673 17.7028 594.673 20.6927V29.382C594.673 30.5344 595.06 31.0794 595.927 31.0794C596.206 31.0794 596.562 31.0016 597.104 30.8458V32.8391H597.119ZM592.289 23.9161C592.289 23.2465 592.335 22.9662 592.366 22.2655C591.344 22.9818 590.554 23.2465 587.891 23.6826L584.315 24.2276C580.15 24.8972 578.896 25.7225 578.896 27.7781C578.896 30.1139 580.862 31.4532 584.237 31.4532C587.024 31.4532 589.502 30.4254 591.189 28.5256C592.01 27.5445 592.242 27.0306 592.242 25.925L592.289 23.9161Z"
          fill="#001F1B"
        />
        <path
          d="M539.739 23.5581C539.739 29.3199 535.388 33.5867 529.458 33.5867C523.42 33.5867 519.208 29.4445 519.208 23.4803C519.208 17.3603 523.451 13.1714 529.566 13.1714C535.574 13.187 539.739 17.4071 539.739 23.5581ZM521.716 23.3713C521.716 28.0274 524.968 31.3443 529.566 31.3443C533.917 31.3443 537.184 27.9962 537.184 23.4958C537.184 18.793 534.01 15.4449 529.535 15.4449C525.014 15.4294 521.716 18.793 521.716 23.3713Z"
          fill="#001F1B"
        />
        <path
          d="M568.537 13.7322L577.332 33.0419H574.591L569.017 20.6463C568.584 19.6652 568.228 18.8243 567.175 16.0992C566.308 18.435 565.874 19.5407 565.332 20.802L559.913 33.0419H557.25L565.921 13.7322H568.537Z"
          fill="#001F1B"
        />
        <path
          d="M612.247 33.0421V28.9777C611.349 30.3636 610.761 30.9865 609.77 31.6561C608.206 32.7617 605.961 33.4002 603.732 33.4002C598.823 33.4002 596.346 31.1111 596.346 26.564V13.7324H598.901V26.0034C598.901 29.6006 600.588 31.2201 604.32 31.2201C607.494 31.2201 610.25 29.803 611.458 27.545C611.845 26.7976 612.046 25.8476 612.046 24.6174V13.7168H614.554V33.0265H612.247V33.0421Z"
          fill="#001F1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_14049_27543">
          <rect
            width="304.603"
            height="31.9994"
            fill="white"
            transform="translate(0.778687 30.4307)"
          />
        </clipPath>
        <clipPath id="clip1_14049_27543">
          <rect
            width="255.659"
            height="77.4723"
            fill="white"
            transform="translate(376.438 0.137695)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(LogoMedsiXPogovorim);
export default Memo;
