import TypedLink, { TypedLinkProps } from '@app/components/TypedLink/TypedLink';
import { COLORS, TYPOGRAPHY } from '@app/constants';
import { RoutesPath } from '@app/routers';
import { useRouter } from 'next/router';
import { FC } from 'react';
import styled from 'styled-components';

interface Props extends TypedLinkProps<RoutesPath> {
  className?: string;
  shouldShowCurrent?: boolean;
  exact?: boolean;
  includedPathSet?: RoutesPath[];
}

const NavLink: FC<Props> = ({
  shouldShowCurrent = true,
  className,
  children,
  exact = true,
  includedPathSet,
  ...props
}) => {
  const router = useRouter();

  const isCurrent = exact
    ? (includedPathSet?.includes(router.pathname as RoutesPath) ||
        router.pathname === props.to) &&
      shouldShowCurrent
    : router.pathname.includes(props.to as string) && shouldShowCurrent;

  return (
    <TypedLink {...props}>
      <Root className={className} data-current={isCurrent}>
        {children}
      </Root>
    </TypedLink>
  );
};

export default NavLink;

const Root = styled.a`
  display: inline-block;
  ${TYPOGRAPHY.body1Regular14}

  &:not([data-current='true']) {
    color: ${COLORS.grayscale500};
  }

  &:active {
    color: ${COLORS.primary500};
  }

  &:hover,
  &[data-current='true'] {
    color: ${COLORS.primary400};
  }
`;
